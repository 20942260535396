<template>
  <div>
    <br />
    <Card style="width: 20em">
      <template #header>
        <div style="display: flex; justify-content: center">
          <Button
            v-if="isuser"
            style="margin-left: 5px; margin-top: 10px; position: absolute"
            label="Modifier"
            @click="onModify"
            icon="pi pi-user-edit"
            iconPos="right"
          />
          <img
            v-bind:alt="userItem.prenom"
            v-bind:src="userItem.photo.replace('upload/', 'upload/h_300/')"
            style="width: 80%; margin-top: 25px; border-radius: 50%"
          />
        </div>
      </template>
      <template #title>{{ userItem.prenom }} {{ userItem.nom }}</template>
      <template #content
        >{{ userItem.email }}<br />{{ userItem.mobile }}</template
      >
    </Card>
    <Dialog
      :visible.sync="popupdisplay"
      :contentStyle="{ overflow: 'visible' }"
    >
      <template #header>
        <h3>Modifier son profil</h3>
      </template>
      <img
        v-bind:alt="userItem.prenom"
        v-bind:src="userItem.photo.replace('upload/', 'upload/h_300/')"
        style="width: 50%; border-radius: 50%"
      />
      <br />
      <UploadCloudinary
        style="margin: 10px"
        folder="user-photos"
        iscamera
        iscrop
        @result="onUploaded($event)"
      />
      <div>
        <label for="prenom">Prénom</label>
        <InputText
          class="input"
          id="prenom"
          v-model="userItem.prenom"
          :autoResize="true"
        />
      </div>
      <div>
        <label for="nom">Nom</label>
        <InputText
          class="input"
          id="nom"
          v-model="userItem.nom"
          :autoResize="true"
        />
      </div>
      <div>
        <label for="email">Email</label>
        <InputText
          type="text"
          class="input"
          id="email"
          v-model="userItem.email"
          :autoResize="true"
          disabled
        />
      </div>
      <div>
        <label for="mobile">Mobile</label>
        <InputText
          class="input"
          id="mobile"
          v-model="userItem.mobile"
          :autoResize="true"
        />
      </div>
      <template #footer>
        <Button
          label="Annuler"
          icon="pi pi-times"
          class="p-button-text"
          @click="closeDialog()"
        />
        <Button
          label="Valider"
          icon="pi pi-check"
          autofocus
          @click="modify()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { store } from "../store";
export default {
  props: ["userItem"],
  computed: {
    isuser() {
      return store.state.userProfile.id === this.userItem.id;
    },
  },
  methods: {
    onModify() {
      this.popupdisplay = true;
    },
    closeDialog() {
      this.popupdisplay = false;
    },
    onUploaded(e) {
      this.userItem.photo = e.secure_url.replace(
        "upload/",
        "upload/c_crop,g_custom/"
      );
    },
    modify() {
      if (
        this.userItem.prenom === "" ||
        this.userItem.nom === "" ||
        this.userItem.mobile === "" ||
        this.userItem.photo === ""
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Impossible de créer le compte",
          detail: "Merci de remplir tous les champs correctement",
          life: 6000,
        });
      } else {
        store.dispatch("updateUser", {
          prenom: this.userItem.prenom,
          nom: this.userItem.nom,
          mobile: this.userItem.mobile,
          email: this.userItem.email,
          photo: this.userItem.photo,
          id: this.userItem.id,
        });

        this.$toast.add({
          severity: "success",
          summary: "Utilisateur modifié",
          detail: "Changements bien pris en compte",
          life: 3000,
        });
        this.closeDialog();
      }
    },
  },
  data() {
    return {
      popupdisplay: false,
    };
  },
};
</script>

<style>
.input {
  margin: 5px;
}
</style>