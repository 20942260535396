<template>
  <div style="max-width: 1250px; margin: auto">
    <div class="p-grid">
      <div
        class="p-col"
        is="users-item"
        v-for="user in Users"
        v-bind:key="user.id"
        :userItem="user"
      ></div>
    </div>
  </div>
</template>

<script>
import Users from "../components/Users.vue";
import { store } from "../store";

export default {
  name: "Trombinoscope",
  components: {
    "users-item": Users,
  },
  computed: {
    Users() {
      return store.state.users;
    },
  },
  created() {
    store.dispatch("bindUsers");
  },
  data() {
    return {
      user: {
        prenom: "",
        nom: "",
        email: "",
        mobile: "",
        photo: "",
      },
    };
  },
};
</script>